import axios from 'axios';
import actionTypes from 'constants/actionTypes.js';
import { history } from 'services/http/httpClients';
import mixpanel from 'mixpanel-browser';
import { DATA_DISTANCE, DEFAULT_NAME_PERMISSION, MIXPANEL_TOKEN } from '../../constants';
import { convertPathname } from 'helper/helper';
import { getLang } from 'common/extensions';
import { message } from 'common/message';
import _ from 'lodash';
import { reLoginDebounce, obtainKeycloakSessionToken } from '../../utils/keycloak';
import { changeUserConfig, saveUserConfigToDatabase } from 'redux/main/mainAction';
import { getPageQuery } from '../../utils';
import { getClientConfig, getUserConfig } from '../../services/mainApi';

export const types = {
    VERIFY_PERMISSION: 'VERIFY_PERMISSION',
};

export function login({ dispatch, getState, token }) {
    axios
        .post('/api/v1.1/login', null, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(async res => {
            if (res.status === 200) {
                if (res.data.status) {
                    const { auth } = getState();
                    dispatch({
                        type: actionTypes.USER_LOGIN_SUCCESS,
                        token,
                    });
                    const params = getPageQuery('#');
                    const language = params?.language || getLang();

                    const clientConfig = await getClientConfig();
                    const userConfigRes = await getUserConfig();

                    if (Boolean(clientConfig.data.status)) {
                        let value = clientConfig.data.data ?? {};
                        if (_.some(value)) dispatch(changeUserConfig({ ...value }));
                    } else {
                        message.error(window.t('Something went wrong'), window.t('Please try again later!'));
                    }
                    if (Boolean(userConfigRes.data.status)) {
                        let value = userConfigRes.data.data ?? {};
                        if (!_.some(value)) value = { language: language };
                        const dbLanguage = _.get(value, 'language');

                        if (!_.isEqual(language, dbLanguage)) {
                            value = { ...value, language: language, distance: DATA_DISTANCE[language][0].id };
                            saveUserConfigToDatabase(value);
                        }
                        dispatch(changeUserConfig(value));
                    } else {
                        message.error(window.t('Something went wrong'), window.t('Please try again later'));
                    }
                    const current_user_config = userConfigRes.data.data;
                    dispatch(saveUserConfigToDatabase({ language: language, ...current_user_config, ...res.data.user }));
                    mixpanel.init(MIXPANEL_TOKEN.token, MIXPANEL_TOKEN.option);
                    let userOjb = {
                        username: res.data.user.username,
                        $email: res.data.user.email,
                        client: res.data.user.client_name,
                        $name: res.data.user.username,
                        USER_ID: res.data.user.id,
                        fullname: res.data.user.first_name + ' ' + res.data.user.last_name,
                        user_type: res.data.user.user_type,
                        account_type: res.data.user.account_type,
                        client_segment: res.data.user.client_segment || '',
                    };
                    mixpanel.identify(res.data.user.username);
                    mixpanel.people.set(userOjb);
                    mixpanel.register(userOjb);
                    mixpanel.track('Login');

                    const url = new URL(window.location.href);
                    const client_hash = url.searchParams.get('client_hash');

                    // To check whether the url includes client_hash or not.
                    if (window.location.pathname === '/' && !client_hash) {
                        const hasAccessToRootPath = _.some(
                            res.data.user.permissions,
                            permission => permission === DEFAULT_NAME_PERMISSION.HOME
                        );
                        if (hasAccessToRootPath) {
                            history.push('/');
                        } else {
                            const findPermission = _.some(res.data.user.permissions)
                                ? res.data.user.permissions?.[0]
                                : auth?.permission[0];
                            const userPermission = findPermission;
                            const permissions = convertPathname(userPermission);
                            history.push(permissions);
                        }
                    }
                } else {
                    throw res;
                }
            }
        })
        .catch(err => {
            dispatch({
                type: actionTypes.USER_LOGIN_FAILED,
            });
            reLoginDebounce();
        });
}
export function reset_password({ email }) {
    return axios
        .post('/api/v1.1/reset_password', { username: email })
        .then(res => {
            if (res.status === 200) {
                if (res.data.status) {
                    message.success();
                }
            }
        })
        .catch(err => {
            console.log('login err: ', err.response);
        });
}

export const verifyPermiss = data => {
    return dispatch =>
        dispatch({
            type: types.VERIFY_PERMISSION,
            data,
        });
};

export function loginByIdP(redirectUri = window.location, loginHint = undefined, prompt = undefined) {
    return async (dispatch, getState) => {
        dispatch({ type: actionTypes.USER_LOGIN_REQUEST });
        const token = await obtainKeycloakSessionToken(redirectUri, loginHint, prompt);
        login({ dispatch, getState, token });
    };
}
