import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Parent from 'layouts/Parent';
import { connect, useSelector } from 'react-redux';
import { convertPathname, verifyPermission } from 'helper/helper';
import { history } from 'services/http/httpClients';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { exception } from 'constants/errorCode';
import { message } from 'common/message';
import { bindActionCreators } from 'redux';
import { loginByIdP } from 'redux/auth/authAction';
import { compareClientId, getPageQuery } from '../utils';
import { useMount } from 'react-use';
import TermsOfService from 'components/Modals/TermsOfService';
import queryString from 'query-string';
import tokenStorage from 'storage/TokenStorage';
import { DEFAULT_NAME_PERMISSION } from '../constants';

const KeycloakHandle = props => {
    const pageParams = getPageQuery();
    const loginHint = pageParams?.login_hint?.toString();
    let prompt = pageParams?.prompt?.toString();

    let redirectUri = window.location.href;
    if (loginHint || prompt) {
        // Forward OIDC login parameters: login_hint & prompt
        // For the parameter details, please refer: https://openid.net/specs/openid-connect-core-1_0.html#AuthRequest
        delete pageParams.loginHint;
        delete pageParams.prompt;
        redirectUri = `${window.location.origin}?${queryString.stringify(pageParams)}`;
    }

    useMount(() => {
        props.loginByIdP(redirectUri, loginHint, prompt);
    });

    return null;
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        loginByIdP: bindActionCreators(loginByIdP, dispatch),
    };
};

const KeycloakPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(KeycloakHandle);

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { permission: permissions, isAuthenticated } = useSelector(state => state.auth);
    let location = useLocation();

    if (isAuthenticated) {
        const pageParams = getPageQuery();
        const action = pageParams?.action?.toString();
        if (action?.toLowerCase() === 'update_password') {
            // Remove another user's token if any, then login
            tokenStorage.del();
        }
    }

    useEffect(() => {
        if (_.some(permissions) && isAuthenticated) {
            // Because we don't limit the permission of Solutions tab right now for MVP
            // If we add the permission for Solutions tab, we need to remove the condition below
            if (location.pathname === '/solutions') return;
            if (location.pathname === '/') {
                const hasAccessToRootPath = permissions.includes(DEFAULT_NAME_PERMISSION.HOME);
                if (!hasAccessToRootPath) {
                    const findPermission = _.some(permissions) ? permissions?.[0] : permissions[0];
                    const userPermission = findPermission;
                    const permission = convertPathname(userPermission);
                    history.push(permission);
                }
                return;
            }

            const permiss = verifyPermission(location.pathname);
            const userPermission = permissions.includes(permiss);
            const path = verifyPermission(permissions[0]);
            const clientId = compareClientId(location.search, permiss);
            if (!userPermission) {
                message.error(window.t(exception[403]), '');
                history.push(path);
            } else if (!clientId) {
                message.error(window.t(exception[403]), '');
                history.push(path);
            }
        }
    }, [permissions, isAuthenticated, location]);

    return (
        <>
            {isAuthenticated && <TermsOfService />}
            <Route
                {...rest}
                render={props =>
                    tokenStorage.get() ? (
                        <Parent>
                            <Component {...rest} {...props} />
                        </Parent>
                    ) : (
                        <KeycloakPage />
                    )
                }
            />
        </>
    );
};

export default ProtectedRoute;
