import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { history } from 'services/http/httpClients';
import { store } from 'redux/store.js';
import { CircularProgress, ThemeProvider, createTheme } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import ProtectedRoute from 'layouts/ProtectedRoute.js';

import 'assets/css/common.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'common/momentExtensions';
import 'common/extensions';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/css/riskAnalyticFilter.css';
import 'assets/css/analyticItems.css';
import 'assets/css/riskAnalytic.css';
import 'assets/css/videoReviews.css';
import 'assets/css/dashboardChart.css';
import 'assets/css/Video/video.css';
import 'assets/css/eventReviews.css';
import 'assets/css/spinner.css';
import 'assets/css/userSettings.css';
import 'assets/css/custom-chart.css';
import 'assets/css/custom-chart.css';
import 'locales';
import 'assets/css/mapbox-gl.css';
import 'assets/css/custom.css';
import 'assets/css/SafetyCenter/eventStyle.css';
import 'assets/css/safetyCenter.css';
import 'assets/css/aware.css';

import ErrorBoundary from 'components/ErrorBoundary';
import Exception from 'components/Exception';

import { withTranslation } from 'react-i18next';
import { MESSAGE_DELAY_IN_MILLISECOND } from 'constants/index';
import tokenStorage from 'storage/TokenStorage';
const EventReview = React.lazy(() => import('pages/EventReview/EventReview.jsx'));
const Login = React.lazy(() => import('pages/Login/Login.js'));
const ClientLogin = React.lazy(() => import('pages/Login/ClientLogin.js'));
const UserSettings = React.lazy(() => import('pages/User/UserSetting.js'));
const ResettingPassword = React.lazy(() => import('pages/Login/ResettingPassword.js'));
const RiskAnalytic = React.lazy(() => import('pages/RiskAnalytic/RiskAnalytic.js'));
const VideoReview = React.lazy(() => import('pages/VideoReview/VideoReview.js'));
const Aware = React.lazy(() => import('pages/Aware/Aware.js'));
const Overview = React.lazy(() => import('pages/Overview/index.js'));
const RiskMitigation = React.lazy(() => import('pages/RiskMitigation/RiskMitigation.js'));

export const theme = createTheme({
    typography: {
        fontFamily: 'Spline Sans',
    },
    palette: {
        secondary: {
            main: '#00C57B',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
            },
        },
    },
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: 0,
        };
    }

    componentDidMount() {
        const self = this;
        const token = tokenStorage.get();
        if (token) {
            if (window.location.pathname === '/login') {
                return (window.location = '/');
            }
        }
        document.showLoading = () => {
            self.setState({ showLoading: this.state.showLoading + 1 });
        };
        document.hideLoading = () => {
            self.setState({ showLoading: Math.max(this.state.showLoading - 1, 0) });
        };
        window.t = this.props.t;
    }

    render() {
        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <ToastContainer hideProgressBar={true} autoClose={MESSAGE_DELAY_IN_MILLISECOND} />
                    <Router history={history}>
                        <ErrorBoundary>
                            <Suspense
                                fallback={
                                    <div className="display-center full-screen">
                                        <CircularProgress />
                                    </div>
                                }
                            >
                                <Switch>
                                    <ProtectedRoute path="/event-review" exact component={EventReview} />
                                    <Route path="/login" exact component={Login} />
                                    <ProtectedRoute path="/user/settings" exact component={UserSettings} />
                                    <Route path="/reset-password" exact component={ResettingPassword} />
                                    <ProtectedRoute path="/analytics" exact component={RiskAnalytic} />
                                    <ProtectedRoute path="/video-review" exact component={VideoReview} />
                                    <ProtectedRoute path="/solutions" exact component={RiskMitigation} />
                                    <Route path="/client-login" exact component={ClientLogin} />
                                    <ProtectedRoute path="/dashboard" exact component={Aware} />
                                    <ProtectedRoute path="/" exact component={Overview} />

                                    <Route path="/exception" exact component={Exception} />
                                    <Redirect to="/exception" />
                                </Switch>
                            </Suspense>
                        </ErrorBoundary>
                    </Router>
                    {process.env.REACT_APP_NODE_ENV === 'development' && <div className="app-version">v1.2</div>}
                    <Backdrop className="main-backdrop" open={!!this.state.showLoading}>
                        <Player
                            autoplay
                            loop
                            src="https://assets8.lottiefiles.com/packages/lf20_35KHAW.json"
                            style={{ height: '100px', width: '100px' }}
                        >
                            <Controls visible={false} />
                        </Player>
                    </Backdrop>
                </ThemeProvider>
            </Provider>
        );
    }
}

export default withTranslation()(App);
