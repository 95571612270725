import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Backdrop from '@material-ui/core/Backdrop';
import BackdropImage from '../../../assets/img/tos_backdrop.png';
import DialogHeaderImage from '../../../assets/img/tos_dialog_header.png';
import CSLogo from '../../../assets/img/cs-logo.png';
import { CommonButton, CommonButtonSubmit } from 'components/Common/InputCommon';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import { handleLogout } from '../../../utils';
import moment from 'moment';
import { APPLICATIONS } from 'constants/index';
import { axiosInstance } from 'services/http/httpClients';
import userStorage from 'storage/UserStorage';
const { REACT_APP_LAST_UPDATE_TOS, REACT_APP_TOS_URL } = process.env;

const useStyles = makeStyles(theme => ({
    container: {
        zIndex: 9999,
        backgroundImage: `url(${BackdropImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
    },
    bg: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 10000,
    },
    dialog: {
        position: 'relative',
        minWidth: 490,
        minHeight: 512,
        zIndex: 10000,
        display: 'flex',
        flexDirection: 'column',
    },
    dialogHeaderImg: {
        width: 490,
        minHeight: 256,
        backgroundImage: `url(${DialogHeaderImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
    },
    dialogContent: {
        width: 490,
        minHeight: 256,
        backgroundColor: 'white',
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    logo: {
        width: 48,
        height: 48,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 4,
        backgroundImage: `url(${CSLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
    },
    actionContainer: {
        display: 'flex',
        gap: 16,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    divider: {
        borderBottom: '1px solid #1A1A1A',
        width: '100%',
        opacity: 0.1,
    },
    title: {
        fontWeight: 600,
        fontSize: 20,
        color: '#01232C',
        marginTop: 10,
    },
    description: {
        fontWeight: 400,
        fontSize: 16,
        color: '#949899',
    },
    agreeCheckbox: {
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        color: '#00C57B !important',
        textDecoration: 'underline !important',
    },
    submitButton: {
        marginTop: '0 !important',
        '&:disabled': {
            color: '#FFFFFF',
            background: '#E1E5E5',
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
        },
        '&:disabled:hover': {
            color: '#FFFFFF',
            cursor: 'not-allowed',
            background: '#E1E5E5',
            pointerEvents: 'all !important',
        },
    },
}));

const needConfirm = accept_terms_at => {
    const lastChangeTOSDate = moment(REACT_APP_LAST_UPDATE_TOS);
    const lastAcceptDate = moment(accept_terms_at);
    return !!(!accept_terms_at || lastAcceptDate.isBefore(lastChangeTOSDate) || lastAcceptDate.isAfter(moment().add(1, 'years')));
};

function TermsOfService(props) {
    const classes = useStyles();
    const [agree, setAgree] = useState(false);
    const currentUser = JSON.parse(userStorage.get());
    const [showConfirm, setShowConfirm] = useState(currentUser.id && needConfirm(currentUser.accept_terms_at));

    const onUpdateUser = updateInfo => {
        axiosInstance()
            .put(`/api/v1.1/user/profile`, updateInfo)
            .then(res => {
                const newUser = { ...currentUser, ...updateInfo };
                userStorage.set(JSON.stringify(newUser));
            });
    };

    const onAccept = () => {
        const accept_terms_at = moment();
        const terms_change_at = moment(REACT_APP_LAST_UPDATE_TOS);
        const accept_terms_application = APPLICATIONS.CUSTOMER;
        const updateInfo = { accept_terms_at, terms_change_at, accept_terms_application };
        onUpdateUser(updateInfo);
        setShowConfirm(false);
    };

    const onPreview = () => {
        const preview_terms_at = moment();
        const terms_change_at = moment(REACT_APP_LAST_UPDATE_TOS);
        const accept_terms_application = APPLICATIONS.CUSTOMER;
        const updateInfo = { preview_terms_at, terms_change_at, accept_terms_application };
        onUpdateUser(updateInfo);
    };

    return window.t ? (
        <Backdrop open={showConfirm} className={classes.container}>
            <div className={classes.bg} />
            <div className={classes.dialog}>
                <div className={classes.dialogHeaderImg} />
                <div className={classes.dialogContent}>
                    <span className={classes.title}>{window.t('Welcome to CompScience')}</span>
                    <span className={classes.description}>{window.t('Providing security for your business')}</span>
                    <div className={classes.divider} />
                    <div className={classes.agreeCheckbox}>
                        <Checkbox checked={agree} onClick={() => setAgree(!agree)} />
                        <div className={classes.description}>
                            {window.t('Agree to')}&nbsp;
                            <Link
                                onClick={onPreview}
                                className={classes.link}
                                target="_blank"
                                href={REACT_APP_TOS_URL}
                                underline="always"
                            >
                                {window.t('Terms of Service.')}
                            </Link>
                        </div>
                    </div>
                    <div className={classes.actionContainer}>
                        <CommonButton style={{ width: '100%', height: 40 }} onClick={() => handleLogout()}>
                            {window.t('Logout')}
                        </CommonButton>
                        <CommonButtonSubmit className={classes.submitButton} onClick={onAccept} disabled={!agree}>
                            {window.t('Accept')}
                        </CommonButtonSubmit>
                    </div>
                </div>
                <div className={classes.logo} />
            </div>
        </Backdrop>
    ) : null;
}

export default TermsOfService;
