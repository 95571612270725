// COPYRIGHT (C) [2023], CompScience, Inc.
// This software is proprietary and confidential. Unauthorized copying,
// distribution, modification, or use is strictly prohibited. This software is provided
// "as is," without warranty of any kind.

import { axiosInstance } from './http/httpClients';
import { accountRefreshToken } from '../utils/keycloak';
import jwt_decode from 'jwt-decode';
import { paramsToUrl } from 'utils';
import tokenStorage from 'storage/TokenStorage';
import refreshTokenStorage from 'storage/RefreshTokenStorage';

export const getEventTypeAPI = () => {
    return axiosInstance().get('/api/v1.1/event_type');
};

export const getMetricTypeAPI = () => {
    return axiosInstance().get('/api/metrics/v1.0/metric/types');
};

export const getMetricTypeAPI1 = async () => {
    return await axiosInstance().get('/api/v1.1/aware/metric/type');
};

export const streamCsvData = async params => {
    return new Promise(async (resolve, reject) => {
        try {
            let token = tokenStorage.get();

            const decodeToken = jwt_decode(token, { complete: true });
            const currentDate = new Date();
            const expectedDate = currentDate.getTime() - 60 * 1000 * 5;
            if (expectedDate < decodeToken.exp) {
                token = await accountRefreshToken();
            }
            const queryText = paramsToUrl(params);
            const url_str = `/api/v1.1/export?${queryText}`;
            const response = await fetch(url_str, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const getPermission = async () => {
    return await axiosInstance().get(`/api/v1.1/verify-permission`);
};

export const getDashboardIdAPI = async dashboardId => {
    return await axiosInstance().get(`/api/v1.1/dashboards/${dashboardId}`);
};

export const postClientCustomizeAPI = async () => {
    return axiosInstance().post('/api/v1.1/user/data');
};

export const getLocationAPI = params => {
    return axiosInstance().get('/api/v1.1/locations', params);
};
export const getCameraAPI = () => {
    return axiosInstance().get('/api/v1.1/camera');
};

export const getBuildingAPI = () => {
    return axiosInstance().get('/api/v1.1/floorplan');
};

export const getUserConfig = async () => {
    return axiosInstance().get('/api/v1.1/user/config');
};

export const updateUserConfig = params => {
    return axiosInstance().put('/api/v1.1/user/config', { ...params });
};

export const getClientConfig = () => {
    return axiosInstance().get('/api/v1.1/client/config');
};

export const updateClientConfig = params => {
    return axiosInstance().put('/api/v1.1/client/config', { ...params });
};

export const getAnnouncements = params => {
    return axiosInstance().get('/api/v1/announcement/user/current', { params: params });
};

export const sendAnnouncementInteractions = params => {
    return axiosInstance().put('/api/v1/announcement/user/interact', { ...params });
};
