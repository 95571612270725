import Axios from 'axios';
import _ from 'lodash';
import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { setActiveEvent } from '../../actions/eventsAction';
import {
    getCommentAPI,
    getEventTagAPI,
    getTagCategoryAPI,
    getTagsListAPI,
    getTrackingAPI,
    getVideoTrackingAPI,
} from '../../services/eventApis';
import { history } from '../../services/http/httpClients';
import { getComment, getEventTags, getTagCategory, getTagsList, getTracking, getVideoTracking, types } from './eventAction';
import { exception } from 'constants/errorCode';
// import { default as testData } from "components/EventReview/Components/tracking.json";

export default function* MainSagas() {
    yield all([
        takeLatest(getComment.REQUEST, watchGetComment),
        takeLatest(getVideoTracking.REQUEST, watchGetVideoTracking),
        takeLatest(getTracking.REQUEST, watchGetTracking),
        // takeLatest(actionTypes.RESET_EVENT, watchReset),
        takeLatest(types.REJECT_EVENT, watchRejectEvent),
        takeLatest(getEventTags.REQUEST, watchGetEventTags),
        takeLatest(getTagsList.REQUEST, watchGetTagsList),
        takeLatest(getTagCategory.REQUEST, watchGetTagCategory),
    ]);
}

export function* watchGetComment(eventId) {
    try {
        // yield delay(300);
        // console.log({ eventId });
        const response = yield call(getCommentAPI, eventId?.data);
        if (response && response.status === 200) {
            yield put(getComment.success(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getComment.failure(window.t(exception['500'])));
    }
}

export function* watchGetVideoTracking(eventId) {
    try {
        // yield delay(300);
        // console.log({ eventId });
        const response = yield call(getVideoTrackingAPI, eventId?.data);
        if (response && response.status === 200) {
            yield put(getVideoTracking.success(response.data));
            // yield put(getTracking.request(eventId?.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getVideoTracking.failure(window.t(exception['500'])));
    }
}

export const processTrackingFile = data => {
    // const { duration } = this.state;
    const results = data.results || {};
    const boxes = {};
    Object.keys(results).forEach(frameValue => {
        const frame = results[frameValue];
        frame.forEach(bbox => {
            if (bbox.class !== 'Goods') {
                const box_id = bbox.id;
                if (!boxes[box_id]) {
                    boxes[box_id] = {};
                }
                boxes[box_id][frameValue] = bbox.bbox;
                boxes[box_id][frameValue].push(bbox.class);
                boxes[box_id][frameValue].push(Object.assign({}, bbox));
            }
        });
    });
    data.results = boxes;
    // let fps = data.video_fps === 14 ? 14.99 : data.video_fps === 29 ? 29.97 : data.video_fps;
    // data.sliderBar = (data?.path_data ?? []).map((item) => {
    //     if (item.end_frame > (fps * duration)) item.end_frame = fps * duration;
    //     let width = Math.round(((item.end_frame - item.start_frame) / (fps * duration)) * 100) + "%",
    //         left = Math.round((item.start_frame / (fps * duration)) * 100) + "%",
    //         objectClass = item.class;
    //     return { width, left, objectClass };
    // });
    return data;
};

export function* watchGetTracking(eventId) {
    try {
        // yield delay(300);
        const response = yield call(getTrackingAPI, eventId?.data);
        if (response && response.status === 200) {
            // response.data
            if (_.isString(_.get(response, 'data.data'))) {
                const getData = yield Axios.get(_.get(response, 'data.data'), {
                    headers: {
                        'Content-type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    },
                });
                if (_.some(getData.data)) {
                    const trackingfile = processTrackingFile(getData.data);
                    yield put(getTracking.success(trackingfile));
                } else {
                    throw response;
                }
            } else if (_.some(_.get(response, 'data.data'))) {
                const trackingfile = processTrackingFile(response.data);
                yield put(getTracking.success(trackingfile));
            }
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getTracking.failure(window.t(exception['500'])));
    }
}

// export function* watchReset() {
//     try {
//         // yield delay(300);
//         // yield source.cancel();
//     } catch (error) {
//         console.log({ watchReset: error });
//     }
// }

export function* watchRejectEvent(eventId) {
    try {
        // yield delay(300);
        const getDataEvents = state => state.events.dataEvents;
        const getCurrentEvent = state => state.events.activeEvent;
        const activeEvent = yield select(getCurrentEvent);
        const dataEvents = yield select(getDataEvents);
        if (_.isEmpty(dataEvents)) {
            yield put(setActiveEvent({ data: {}, index: 0 }));
            yield history.push('/event-review');
        } else {
            yield put(setActiveEvent({ data: dataEvents[activeEvent.index], index: activeEvent.index }));
        }
    } catch (error) {}
}

export function* watchGetEventTags(eventId) {
    try {
        // yield delay(300);
        const response = yield call(getEventTagAPI, {
            eventId: _.get(eventId, 'data'),
        });
        if (response && response.status === 200) {
            yield put(getEventTags.success(response.data.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getEventTags.failure(window.t(exception['500'])));
    }
}

export function* watchGetTagsList(eventId) {
    try {
        // yield delay(300);
        const response = yield call(getTagsListAPI, { keyword: _.get(eventId, 'data') });
        if (response && response.status === 200) {
            yield put(getTagsList.success(response.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getTagsList.failure(window.t(exception['500'])));
    }
}

export function* watchGetTagCategory() {
    try {
        // yield delay(300);
        const response = yield call(getTagCategoryAPI);
        if (response && response.status === 200) {
            yield put(getTagCategory.success(response.data.data));
        } else {
            throw response;
        }
    } catch (error) {
        yield put(getTagCategory.failure(window.t(exception['500'])));
    }
}
