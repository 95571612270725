import moment from 'moment';
import { axiosInstance } from 'services/http/riskMitigationClient';
const ACCEPT_TOKEN_VERSION = 2.15;

const getParamsForSearch = query => {
    const params = { ...query };
    if (query.order) {
        if (query.order === 'date') {
            params.order = `truthfulTargetDate:DESC,targetDate:ASC,riskMitigationPriority:ASC`;
        }
        if (query.order === 'priority') {
            params.order = `riskMitigationPriority:ASC,truthfulTargetDate:DESC,targetDate:DESC`;
        }
    }
    if (query.targetDateFrom) {
        params.targetDateFrom = moment(query.targetDateFrom)
            .startOf('D')
            .toISOString();
    }
    if (query.targetDateTo) {
        params.targetDateTo = moment(query.targetDateTo)
            .endOf('D')
            .toISOString();
    }

    return params;
};

export const getRiskMitigationActionItemAPI = query => {
    return axiosInstance().get(`/actions`, {
        params: getParamsForSearch(query),
    });
};

export const getCausalRiskFactorsAPI = () => {
    return axiosInstance().get(`/causal-risk-factors`);
};

export const getRiskMitigationPrioritiesAPI = () => {
    return axiosInstance().get(`/priorities`);
};

export const updateRiskMitigationActionItemApi = (id, data) => {
    return axiosInstance().put(`/actions/${id}/completion`, data);
};
