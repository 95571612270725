import axios from 'axios';
import axiosCancel from 'axios-cancel';
import { getLang } from 'common/extensions';
import { accountRefreshToken, reLoginDebounce } from 'utils/keycloak';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import tokenStorage from 'storage/TokenStorage';
import { isTokenExpiredSoon, safe_jwt_decode } from './httpClients';

const ACCEPT_TOKEN_VERSION = 2.15;

export const axiosInstance = () => {
    if (!document.riskMitigationAxios) {
        document.riskMitigationAxios = axios.create({
            baseURL: process.env.REACT_APP_RISK_MITIGATION_BASE_API_URL,
            headers: {
                'Content-type': 'application/json',
                Language: getLang(),
            },
            timeout: 30000,
        });
        axiosCancel(document.riskMitigationAxios, {
            debug: true, // default
        });
        document.riskMitigationAxios.interceptors.request.use(
            async config => {
                let token = tokenStorage.get();
                if (token) {
                    const decodeToken = safe_jwt_decode(token);
                    const currentVersion = get(decodeToken, 'version');
                    if (
                        !currentVersion ||
                        toNumber(currentVersion) < ACCEPT_TOKEN_VERSION ||
                        isTokenExpiredSoon(decodeToken, 3)
                    ) {
                        token = await accountRefreshToken();
                    }
                }

                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                }

                return config;
            },
            error => {
                if (error?.response?.status === 401) {
                    reLoginDebounce();
                } else {
                    return Promise.reject(error);
                }
            }
        );

        document.riskMitigationAxios.interceptors.response.use(
            res => {
                return res;
            },
            async err => {
                const originalConfig = err.config;

                if (err.response?.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        await accountRefreshToken();
                        return document.riskMitigationAxios(originalConfig);
                    } catch (_error) {
                        reLoginDebounce();
                    }
                } else if (err.response?.status === 401 && originalConfig._retry) {
                    reLoginDebounce();
                } else {
                    return Promise.reject(err);
                }
            }
        );
    }
    return document.riskMitigationAxios;
};
