import actionTypes from 'constants/actionTypes.js';
import { axiosInstance } from 'services/http/httpClients';
import { EVENT_REVIEW_ITEM_PER_PAGE, DEFAULT_ALL_LOCATION_ID } from 'constants/index.js';
import moment from 'common/momentExtensions';
import { exception } from 'constants/errorCode';
import { message } from 'common/message';
import Axios from 'axios';
import _ from 'lodash';

const CancelToken = Axios.CancelToken;
export const source = CancelToken.source();

const callFetchEvents = (url, dispatch, isLoadNextPage) => {
    return axiosInstance()
        .get(url, {
            cancelToken: source.token,
        })
        .then(response => {
            if (response.status === 200 && response.data.data) {
                let events = {
                    data: [],
                    page: 0,
                    totalCount: 0,
                };
                if (response.data.data && response.data.data.length > 0) {
                    events = {
                        data: response.data.data,
                        page: response.data.page,
                        totalCount: response.data.totalCount,
                    };
                }
                dispatch({
                    type: actionTypes.FETCH_EVENTS_SUCCESS,
                    events: {
                        ...events,
                        isLoadNextPage,
                    },
                });
                return events;
            } else {
                throw response;
            }
        })
        .catch(err => {
            message.error(
                err?.response?.data?.message || window.t('Insufficient Permissions'),
                window.t('Make sure you have the correct permission to perform this action')
            );
            dispatch({
                type: actionTypes.FETCH_EVENTS_FAILURE,
                error: _.get(err, 'response.data.messange') ?? exception[_.get(err, 'response.status')],
            });
        });
};

const callFetchTotalEvents = (url, dispatch) => {
    return axiosInstance()
        .get(url, {
            cancelToken: source.token,
        })
        .then(response => {
            if (response.status === 200 && response.data) {
                let totalCount = 0;
                if (response.data) {
                    totalCount = response.data.totalCount;
                }
                dispatch({
                    type: actionTypes.FETCH_TOTAL_EVENTS_SUCCESS,
                    totalCount: totalCount,
                });
                return totalCount;
            } else {
                throw response;
            }
        })
        .catch(err => {
            dispatch({
                type: actionTypes.FETCH_TOTAL_EVENTS_FAILURE,
                error: _.get(err, 'response.data.messange') ?? exception[_.get(err, 'response.status')],
            });
        });
};

export function fetchEvents({
    event_id,
    state,
    flag,
    sort,
    sort_date,
    sort_severity,
    event_type,
    event_master_type,
    page = 0,
    pageSize = EVENT_REVIEW_ITEM_PER_PAGE,
    camera,
    severity,
    date_from,
    date_to,
    keyword,
    event_source,
    location,
    weekdays,
    hours,
    tags,
    exclude_ids,
    exclude_total,
    only_total,
    isLoadNextPage,
}) {
    return async dispatch => {
        let url = `/api/v1.1/events?page=${page}&pageSize=${pageSize}`;
        let params = [];
        let mixPanelTrackingDate = {};
        if (tags) {
            params.push(`tags=${tags}`);
        }
        if (sort && sort !== 'none') {
            params.push(`sort=${sort}`);
        }
        if (sort_date && sort_date !== 'none') {
            params.push(`sort_date=${sort_date}`);
        }
        if (sort_severity && sort_severity !== 'none') {
            params.push(`sort_severity=${sort_severity}`);
        }
        if (state && state.toString() !== '0') {
            params.push(`state=${state}`);
        }
        if (location && location !== DEFAULT_ALL_LOCATION_ID) {
            params.push(`location=${location}`);
        }
        if (camera) {
            params.push(`camera=${camera}`);
        }
        if (flag) {
            params.push(`flag=${flag}`);
        }
        if (severity) {
            params.push(`severity=${severity}`);
        }
        if (event_type) {
            params.push(`event_type=${event_type}`);
        }
        if (event_master_type) {
            params.push(`event_master_type=${event_master_type}`);
        }
        if (date_from) {
            params.push(`from_date=${date_from}`);
            mixPanelTrackingDate.date_from = moment(date_from * 1000).format('YYYY-MM-DD');
        }
        if (date_to) {
            params.push(`to_date=${date_to}`);
            mixPanelTrackingDate.date_to = moment(date_to * 1000).format('YYYY-MM-DD');
        }
        if (event_id) {
            params.push(`event_id=${event_id}`);
        }
        if (keyword) {
            params.push(`keyword=${encodeURIComponent(keyword)}`);
        }
        if (event_source) {
            params.push(`event_source=${event_source}`);
        }
        if (weekdays) {
            params.push(`weekdays=${weekdays}`);
        }
        if (hours) {
            params.push(`hours=${hours}`);
        }
        if (exclude_ids) {
            params.push(`exclude_ids=${exclude_ids}`);
        }
        if (exclude_total) {
            params.push(`exclude_total=${exclude_total}`);
        }
        if (mixPanelTrackingDate.date_from || mixPanelTrackingDate.date_to) {
            // mixpanel.track("Dates selected", mixPanelTrackingDate);
        }
        if (params.length > 0) {
            url += '&' + params.join('&');
        }

        if (only_total) {
            url += `&only_total=${only_total}`;
            dispatch({
                type: actionTypes.FETCH_TOTAL_EVENTS_REQUEST,
            });
            return await callFetchTotalEvents(url, dispatch);
        } else {
            dispatch({
                type: actionTypes.FETCH_EVENTS_REQUEST,
                isLoadNextPage,
            });
            return await callFetchEvents(url, dispatch, isLoadNextPage);
        }
    };
}

export function fetchEventsWithMasterType({ status, sortType, page = 0, pageSize = 3, camera, severity, type }) {
    return dispatch => {
        return axiosInstance()
            .get('/api/v1.1/analytic/chart_ids')
            .then(response => {
                if (response.status === 200) {
                    let event_master_type = '';
                    response.data.data.forEach(item => {
                        event_master_type += `${item.type_ids},`;
                    });
                    event_master_type = event_master_type.substring(0, event_master_type.length - 1);
                    let url = `/api/v1.1/events?page=${page}&pageSize=${pageSize}&sort=${sortType}`;
                    let params = [];
                    if (status) {
                        params.push(`state=${status}`);
                    }
                    if (camera) {
                        params.push(`camera=${camera}`);
                    }
                    if (severity) {
                        params.push(`severity=${severity}`);
                    }
                    if (type) {
                        params.push(`event_type=${type}`);
                    }
                    if (event_master_type) {
                        params.push(`event_master_type=${event_master_type}`);
                    }
                    url += '&' + params.join('&');
                    return axiosInstance()
                        .get(url)
                        .then(response => {
                            if (response.status === 200 && response.data.data) {
                                let events = {
                                    data: [],
                                    page: 0,
                                    totalCount: 0,
                                    // activeEvent: null,
                                    // activePage: 1,
                                };
                                if (response.data.data && response.data.data.length > 0) {
                                    events = {
                                        data: response.data.data,
                                        page: response.data.page,
                                        totalCount: response.data.totalCount,
                                        // activeEvent: events[0],
                                        // activePage: page && page > 0 ? page + 1 : 1,
                                    };
                                }
                                dispatch({
                                    type: actionTypes.FETCH_EVENTS,
                                    events: events,
                                    totalCount: response.data.totalCount,
                                });
                                return events;
                            } else {
                                throw response;
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }
            })
            .catch(error => console.log(error.response));
    };
}

export function flagEvent({ eventId, isFlag = false }) {
    return dispatch => {
        return axiosInstance()
            .put(`/api/v1.1/events/${eventId}/flag`, {
                flag: !!isFlag,
            })
            .then(response => {
                return response.data.status;
            })
            .catch(err => {});
    };
}

export function fetchTimelineData({ fromDate, toDate, isTitle }) {
    return dispatch => {
        let params = [];
        if (fromDate) {
            params.push('from_date=' + fromDate);
        }
        if (toDate) {
            params.push('to_date=' + toDate);
        }
        if (isTitle) {
            params.push('title=1');
        }

        let urlParams = '';
        if (params.length > 0) {
            urlParams = '?' + params.join('&');
        }
        return axiosInstance()
            .get(`/api/v1.1/events/timeline${urlParams}`)
            .then(response => {
                return response.data.data;
            })
            .catch(err => {});
    };
}

export function logErrorVideo({ type, group, content }) {
    return dispatch => {
        return axiosInstance()
            .post('/api/v1.1/logging', { type, group, content })
            .then(response => {
                return response.data.data;
            })
            .catch(err => {
                return err.response;
            });
    };
}

export function updateEventState({ id, severity_id, state_id, status }) {
    return dispatch => {
        dispatch({ type: actionTypes.UPDATE_EVENT_STATE, id, severity_id, state_id, status });
    };
}

export function updateEvent({ id, comments, event_type, event_type_parent }) {
    return dispatch => {
        dispatch({ type: actionTypes.UPDATE_EVENT, id, comments, event_type, event_type_parent });
    };
}

export function updateEventType({ id, event_type, event_type_id, event_type_parent, event_type_parent_id }) {
    return dispatch => {
        dispatch({ type: actionTypes.UPDATE_EVENT_TYPE, id, event_type, event_type_id, event_type_parent, event_type_parent_id });
    };
}

export function setActiveEvent(event) {
    return dispatch => {
        dispatch({ type: actionTypes.SET_ACTIVE_EVENT, event: event });
    };
}

export function setActivePage({ page }) {
    return dispatch => {
        dispatch({ type: actionTypes.SET_ACTIVE_PAGE, activePage: page });
    };
}

export function setDataEvent(event) {
    return dispatch => {
        dispatch({ type: actionTypes.SET_DATA_EVENT, event: event });
    };
}

export function resetEvent() {
    return dispatch => {
        dispatch({ type: actionTypes.RESET_EVENT });
    };
}
