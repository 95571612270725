// COPYRIGHT (C) [2023], CompScience, Inc.
// This software is proprietary and confidential. Unauthorized copying,
// distribution, modification, or use is strictly prohibited. This software is provided
// "as is," without warranty of any kind.

import moment, { HTML5_FMT } from 'moment';
import CARRIER_IMAGE_FILE_NAME from './carrierImageFileName';

const TYPES = {
    SAFETY_GEAR: 'SAFETY GEAR',
    FALL_HAZARD: 'FALL Hazard',
};

export const SORT_NAME = {
    SORT_SEVERITY: 'sort_severity',
    SORT_DATE: 'sort_date',
    SORT: 'sort',
};

export const TAGS = 'tags';

const DEFAULT_NAME_PERMISSION = {
    DASHBOARD: 'Dashboard',
    SAFETY_CENTER: 'Safety Center',
    EVENT_REVIEW: 'Event Review',
    RISK_ANALYTIC: 'Risk Analytics',
    VIDEO_REVIEW: 'Video Review',
    USER_SETTING: 'User Setting',
    SOLUTIONS: 'Solutions',
    HOME: 'Home',
};

const DEFAULT_COLOR_HEATMAP_RANGE = [
    [255, 255, 178],
    [254, 217, 118],
    [254, 178, 76],
    [253, 141, 60],
    [240, 59, 32],
    [189, 0, 38],
];

const LANGUAGE = [{ id: 'en', name: 'English' }, { id: 'ja', name: '日本語' }];

const SUB_TYPES = {
    SPILL: 'Spill',
    GLASSES: 'Glasses',
};

const DEFAULT_ALL_LOCATION_ID = 'all_locations';
const DEFAULT_ALL_LOCATION = { id: DEFAULT_ALL_LOCATION_ID, name: 'All locations' };
const DEFAULT_CAMERA = { id: 0, name: 'All cameras' };
const DEFAULT_BUILDING = { id: 0, name: 'All buildings' };
const DEFAULT_TAG_CATEGORY = { id: 0, name: 'General' };

const OSHA_CLASSIFICATION = [
    { id: 'N/A', name: 'N/A' },
    { id: 'Hazard', name: 'Hazard' },
    { id: 'Near Miss', name: 'Near Miss' },
    { id: 'Recordable', name: 'Recordable' },
    { id: 'Reportable', name: 'Reportable' },
];
const SEVERITIES = [{ id: 1, name: 'Low' }, { id: 2, name: 'Medium' }, { id: 3, name: 'High' }];
const DEFAULT_DASHBOARD = { id: 1, name: 'Default' };

const STATUSES = {
    all: { text: 'all', id: 0 },
    new: { text: 'new', id: 1 },
    confirmed: { text: 'confirmed', id: 3 },
    dismissed: { text: 'dismissed', id: 4 },
    rejected: 'rejected',
    archived: { text: 'archived', id: 6 },
    cleared: { text: 'cleared', id: 5 },
};

const STATUSES_VALUE = {
    all: 'all',
    new: 'KeNew,KeConfirmed',
    confirmed: 'UserConfirmed',
    dismissed: 'UserRejected',
    archived: 'Archived',
};
const SORT_TYPES = {
    NONE: 'none',
    TYPE: 'type',
};

const EVENT_INTERACTS = {
    previous: 'previous',
    next: 'next',
    no_problem: 'no_problem',
    yikes: 'yikes',
    problem: 'problem',
    dismiss: 'dismiss',
};

const CURRENT_USER = {
    user_avatar: 'https://img.icons8.com/bubbles/50/000000/user.png',
};

const SAFETY_CENTER = {
    SHAPES: {
        LAST_30_DAYS: '30',
        LAST_7_DAYS: '7',
        LAST_24H: '1',
    },
    SHAPES_TIMELINE: {
        SAVED: 'SAVED',
        ALL: 'ALL',
    },
};

const COMMENTSTOLOAD = 5;
// const TIMERANGE_7_DAYS = "7"
// const TIMERANGE_30_DAYS = "30";
// const TIMERANGE_24_H = "1";
const EVENT_REVIEW_ITEM_PER_PAGE = 10;

const RISK_ANALYTIC = {
    SHAPES: {
        LIST: 'LIST',
        GRID: 'GRID',
    },
    TYPES: {
        MAP: 'MAP',
        MAP_SPEEDING: 'speeding',
        MAP_NEAR_MISS: 'near_miss',
        GRAPH: 'GRAPH',
    },
};

const MIXPANEL_TOKEN = {
    token: process.env.REACT_APP_MIXPANEL_TOKEN,
    option: { batch_requests: false, protocol: 'https', api_host: 'https://api.mixpanel.com' },
};

const ONE_DAY_MINUTE = 1440;

const DEFAULT_DATE_RANGE = {
    label: 'All Time',
    startDate: undefined,
    endDate: undefined,
    key: 'All Time',
};

const DEFAULT_DATE_RANGE_EVENT_REVIEW = {
    label: 'Last 7 Days',
    startDate: moment
        .utc()
        .subtract(7, 'd')
        .startOf('dates')
        .toDate(),
    endDate: moment.utc().toDate(),
};
const DEFAULT_DATE_RANGE_ALL_EVENT = {
    label: 'Last 30 Days',
    startDate: moment
        .utc()
        .subtract(30, 'd')
        .startOf('dates')
        .toDate(),
    endDate: moment.utc().toDate(),
};
const DEFAULT_DATE_RANGE_REPORT = {
    label:
        moment
            .utc()
            .subtract(6, 'months')
            .format('YYYY/MM/DD') +
        ' - ' +
        moment.utc().format('YYYY/MM/DD'),
    startDate: moment
        .utc()
        .subtract(6, 'months')
        .startOf('dates')
        .toDate(),
    endDate: moment.utc().toDate(),
};

const DEFAULT_FOR_REVIEW = {
    date_from: parseInt(
        moment
            .utc()
            .subtract(6, 'months')
            .startOf('dates')
            .format('X')
    ),
    date_to: parseInt(moment.utc().format('X')),
};

const DEFAULT_WEEKDAYS = [
    { id: 1, name: 'Sun' },
    { id: 2, name: 'Mon' },
    { id: 3, name: 'Tue' },
    { id: 4, name: 'Wed' },
    { id: 5, name: 'Thu' },
    { id: 6, name: 'Fri' },
    { id: 7, name: 'Sat' },
];

const DEFAULT_DATE_RANGES = [
    {
        key: 'today',
        label: 'Today',
        startDate: new Date(),
        endDate: new Date(),
    },
    {
        key: '1,d',
        label: 'Yesterday',
        startDate: moment()
            .subtract(1, 'd')
            .startOf('dates')
            .toDate(),
        endDate: moment().toDate(),
    },
    {
        key: '7,d',
        label: 'Last 7 Days',
        startDate: moment()
            .subtract(7, 'd')
            .startOf('dates')
            .toDate(),
        endDate: moment().toDate(),
    },
    {
        key: 'w',
        label: 'This Week',
        startDate: moment()
            .startOf('weeks')
            .toDate(),
        endDate: moment().toDate(),
    },
    {
        key: '14,d',
        label: 'Last 14 Days',
        startDate: moment()
            .subtract(14, 'd')
            .startOf('dates')
            .toDate(),
        endDate: moment().toDate(),
    },
    {
        key: '30,d',
        label: 'Last 30 Days',
        startDate: moment()
            .subtract(30, 'd')
            .toDate(),
        endDate: moment().toDate(),
    },
    {
        key: 'months',
        label: 'This Month',
        startDate: moment()
            .startOf('months')
            .toDate(),
        endDate: moment().toDate(),
    },
    {
        key: '1,months',
        label: 'Last Month',
        startDate: moment()
            .subtract(1, 'months')
            .startOf('month')
            .toDate(),
        endDate: moment()
            .subtract(1, 'months')
            .endOf('month')
            .toDate(),
    },
    {
        key: '6,months',
        label: 'Last 6 Months',
        startDate: moment()
            .subtract(6, 'months')
            .startOf('dates')
            .toDate(),
        endDate: moment().toDate(),
    },
    {
        key: 'All Time',
        label: 'All Time',
        startDate: null,
        endDate: null,
    },
];

const INIT_DATE_RANGES = {
    filter_date_range: {
        startDate: null,
        endDate: null,
        key: null,
        label: 'All dates',
    },
    dateRangeText: 'Alls dates',
    tmp_filter_date_range: {
        startDate: null,
        endDate: null,
        key: null,
        label: 'All dates',
    },
    tmp_dateRangeText: 'All dates',
};

export const DATE_RANGE_6_MONTHS = DEFAULT_DATE_RANGES.find(date => date.key === '6,months');

export const DEFAUlT_SELECTED_SIX_MONTHS = {
    filter_date_range: DATE_RANGE_6_MONTHS,
    dateRangeText: DATE_RANGE_6_MONTHS.label,
    tmp_dateRangeText: DATE_RANGE_6_MONTHS.label,
    tmp_filter_date_range: DATE_RANGE_6_MONTHS,
};

const DEFAULT_SELECTED_DATE = {
    filter_date_range: DEFAULT_DATE_RANGE_ALL_EVENT,
    dateRangeText: DEFAULT_DATE_RANGE_ALL_EVENT.label,
    tmp_dateRangeText: DEFAULT_DATE_RANGE_ALL_EVENT.label,
    tmp_filter_date_range: DEFAULT_DATE_RANGE_ALL_EVENT,
};

const DEFAULT_SELECT_PERIOD_RANGE = {
    label: 'Last 7 Days',
    id: 7,
};

const DEFAULT_PERIOD_RANGE = [
    {
        label: 'Yesterday',
        id: 1,
    },
    {
        label: 'Last 7 Days',
        id: 7,
    },
    {
        label: 'Last 30 Days',
        id: 30,
    },
];

const DEFAULT_DATE_RANGE_METRIC = [
    {
        label: 'Last 30 Days',
        date_from: moment
            .utc()
            .subtract(30, 'days')
            .startOf('days')
            .format('X'),
        date_to: moment
            .utc()
            .startOf('days')
            .format('X'),
        id: 30,
    },
    {
        label: 'Last 7 Days',
        date_from: moment
            .utc()
            .subtract(7, 'days')
            .startOf('days')
            .format('X'),
        date_to: moment
            .utc()
            .startOf('days')
            .format('X'),
        id: 7,
    },
    {
        label: 'Last 24H',
        date_from: moment
            .utc()
            .subtract(1, 'days')
            .startOf('days')
            .format('X'),
        date_to: moment
            .utc()
            .startOf('days')
            .format('X'),
        id: 1,
    },
];

const DEFAULT_EVENT_TYPE = {
    VisualizationB: { id: 62, name: 'Cargo Falling', color: '#3750cb' },
    VisualizationC: { id: 39, name: 'Forklift - Precarious Load', color: '#55A9F2' },
    VisualizationD: { id: 12, name: 'Blades Up', color: '#ABEBF9' },
    MetricChart1: { color: '#3750cb' },
    MetricChart2: { color: '#55A9F2' },
    MetricChart3: { color: '#ABEBF9' },
    'risk.chart1': { color: '#3750cb' },
    'risk.chart2': { color: '#3750cb' },
};

const DEFAULT_COMPONENT_NAME = {
    'summary.widget1': 'Score',
    'daily.events1': 'daily_event',
    'event.chart1': 'VisualizationB',
    'event.chart2': 'VisualizationC',
    'event.chart3': 'VisualizationD',
    'for.review1': 'ObservationCard',
    // "score.chart1": "Visualization",
    'daily.metric1': 'DailyMetric',
    'metric.chart1': 'MetricChart1',
    'metric.chart2': 'MetricChart2',
    'metric.chart3': 'MetricChart3',
};

const WIDGET_ID = {
    SUMMARY: 'summary.widget1',
    DAILY_EVENT: 'daily.events1',
    EVENT1: 'event.chart1',
    EVENT2: 'event.chart2',
    EVENT3: 'event.chart3',
    FOR_REVIEW: 'for.review1',
    DAILY_METRIC: 'daily.metric1',
    METRIC1: 'metric.chart1',
    METRIC2: 'metric.chart2',
    METRIC3: 'metric.chart3',
};

const COMPONENT_NAME = {
    Score: 'Score',
    daily_event: 'daily_event',
    VisualizationB: 'VisualizationB',
    VisualizationC: 'VisualizationC',
    VisualizationD: 'VisualizationD',
    ObservationCard: 'ObservationCard',
    DailyMetric: 'DailyMetric',
    MetricChart1: 'MetricChart1',
    MetricChart2: 'MetricChart2',
    MetricChart3: 'MetricChart3',
};

const CHART_SELECTED_DEFAULT = {
    id: 2,
    name: 'Area chart',
};

const CHART_DEFAULT = {
    Score: {
        id: 3,
        name: 'Donut chart',
    },
    daily_event: {
        id: 1,
        name: 'Bar chart',
    },
    VisualizationB: {
        id: 2,
        name: 'Area chart',
    },
    VisualizationC: {
        id: 2,
        name: 'Area chart',
    },
    VisualizationD: {
        id: 2,
        name: 'Area chart',
    },
    ObservationCard: {
        id: 4,
        name: 'For Review',
    },
};

const DATA_DATE_FORMAT = [
    { id: 'MM-DD-YYYY', name: 'MM-DD-YYYY' },
    { id: 'YYYY-MM-DD', name: 'YYYY-MM-DD' },
    { id: 'MM/DD/YYYY', name: 'MM/DD/YYYY' },
];

const DATA_DISTANCE = {
    en: [
        { id: 'feet/miles', name: 'USA Imperial units (feet/miles)' },
        { id: 'meters/kilometers', name: 'Metric units (meters/kilometers)' },
    ],
    ja: [
        { id: 'フィート/マイル', name: 'アメリカインペリアル単位（フィート/マイル）' },
        { id: 'メートル/キロメートル', name: 'メートル単位（メートル/キロメートル）' },
    ],
};

const DATA_TIME_FORMAT = [{ id: 'hh:mm A', name: '12-hour am/pm' }, { id: HTML5_FMT.TIME, name: '24-hour' }];

const DEFAULT_AVATAR = 'https://img.icons8.com/bubbles/50/000000/user.png';

const MIN_VIDEO_PLAYBACK_RATE_SUPPORT = 0.07;
const MAX_VIDEO_PLAYBACK_RATE_SUPPORT = 25;
const RESET_PASSWORD_SOURCE = 'frontend';
const EVENT_RESULT_WIDTH = 54;
export const MESSAGE_DELAY_IN_MILLISECOND = 3000; // 5s

export const ICON_NAME = {
    downIcon: 'downIcon',
    upIcon: 'upIcon',
    randomIcon: 'randomIcon',
};

export const SORT_BY = {
    random: 'random',
    severity: 'severity',
    date: 'date',
};

const PARAMETER_DATE_FORMAT = {
    DATE_TIME_WITHOUT_TIMEZONE: 'YYYY-MM-DDTHH:mm:ss',
    DATE: 'YYYY-MM-DD',
    DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
};

const DASHBOARD_CONFIG_DATE_FORMAT = 'YYYY-MM-DD';

const WEEKDAYS = 'weekdays';
const HOURS = 'hours';

const APPLICATIONS = {
    CUSTOMER: 'CUSTOMER',
    UPLOADER: 'UPLOADER',
    ADMIN: 'ADMIN',
    INSPECTOR: 'INSPECTOR',
};

const DAILY_TOTAL = 'Daily Total';

const ALL_TIME = 'All Time';

const ALL_RISK_FACTOR = {
    id: 0,
    name: 'All',
};

const ALL_CAMERAS = 'All cameras';
const TOAST_DURATION_MS = 10000;

const DEFAULT_CLIP_SHARING_BUFFER_TIME = 2; // seconds
const CLIP_SHARING_POLLING_INTERVAL = 5000; // 5 seconds

const USER_ROLES = {
    CS_ADMINISTRATOR: 'KineticAdministrator', // Manage all customers and associated data. Manage Kinetic Eye employee users.
    CS_LOSS_CONTROL_EXPERT: 'LossControlExpert',
    CS_CUSTOMER_SUPPORT: 'KineticCustomerSupport',

    /**
     * Customer user roles take into account
     */
    CUSTOMER_ADMINISTRATOR: 'CustomerAdministrator', // Manage customer data, customer-users within this customer.
    CUSTOMER_SAFETY_MANAGER: 'CustomerSafetyManager', // Reviews and interacts with data in Safety Center.

    /** External access to a client */
    BROKER: 'Broker',
    CUSTOMER_SUPPORT: 'CustomerSupport',
};

export {
    TYPES,
    STATUSES,
    STATUSES_VALUE,
    SUB_TYPES,
    SORT_TYPES,
    EVENT_INTERACTS,
    COMMENTSTOLOAD,
    CURRENT_USER,
    SAFETY_CENTER,
    EVENT_REVIEW_ITEM_PER_PAGE,
    RISK_ANALYTIC,
    MIXPANEL_TOKEN,
    DEFAULT_DATE_RANGES,
    ONE_DAY_MINUTE,
    DEFAULT_DATE_RANGE,
    DEFAULT_DATE_RANGE_ALL_EVENT,
    DEFAULT_SELECTED_DATE,
    DEFAULT_SELECT_PERIOD_RANGE,
    DEFAULT_PERIOD_RANGE,
    DEFAULT_DATE_RANGE_REPORT,
    DEFAULT_EVENT_TYPE,
    DEFAULT_COMPONENT_NAME,
    CHART_DEFAULT,
    CHART_SELECTED_DEFAULT,
    DEFAULT_ALL_LOCATION,
    DEFAULT_CAMERA,
    DEFAULT_TAG_CATEGORY,
    DEFAULT_DATE_RANGE_EVENT_REVIEW,
    DEFAULT_FOR_REVIEW,
    DEFAULT_AVATAR,
    DEFAULT_WEEKDAYS,
    OSHA_CLASSIFICATION,
    SEVERITIES,
    DEFAULT_DASHBOARD,
    INIT_DATE_RANGES,
    DEFAULT_DATE_RANGE_METRIC,
    DEFAULT_COLOR_HEATMAP_RANGE,
    DEFAULT_BUILDING,
    LANGUAGE,
    DATA_DATE_FORMAT,
    DATA_DISTANCE,
    DATA_TIME_FORMAT,
    MIN_VIDEO_PLAYBACK_RATE_SUPPORT,
    MAX_VIDEO_PLAYBACK_RATE_SUPPORT,
    RESET_PASSWORD_SOURCE,
    DEFAULT_NAME_PERMISSION,
    COMPONENT_NAME,
    WIDGET_ID,
    EVENT_RESULT_WIDTH,
    PARAMETER_DATE_FORMAT,
    DASHBOARD_CONFIG_DATE_FORMAT,
    WEEKDAYS,
    HOURS,
    APPLICATIONS,
    DAILY_TOTAL,
    ALL_TIME,
    ALL_RISK_FACTOR,
    ALL_CAMERAS,
    DEFAULT_ALL_LOCATION_ID,
    TOAST_DURATION_MS,
    DEFAULT_CLIP_SHARING_BUFFER_TIME,
    CLIP_SHARING_POLLING_INTERVAL,
    USER_ROLES,
    CARRIER_IMAGE_FILE_NAME,
};
