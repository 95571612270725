import { axiosInstance } from './http/httpClients';

export const getTimeMarkCategoriesApi = () => {
    return axiosInstance().get(`/api/v1.1/timemarkcategory`);
};

export const getTimeMarksByCategoryApi = categoryId => {
    return axiosInstance().get(`/api/v1.1/timemark?mark_category=${categoryId}`);
};

export const createTimeMarkApi = payload => {
    return axiosInstance().post(`/api/v1.1/timemark`, payload);
};

export const deleteTimeMarkApi = id => {
    return axiosInstance().delete(`/api/v1.1/timemark/${id}`);
};

export const updateTimeMarkApi = payload => {
    return axiosInstance().put(`/api/v1.1/timemark/${payload.id}`, payload);
};

export const getTimeMarkIconApi = payload => {
    return axiosInstance().get(`/api/v1.1/timemark/icon`, payload);
};

export const getAllTimeMarksApi = () => {
    return axiosInstance().get(`/api/v1.1/timemark`);
};
