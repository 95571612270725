import { axiosInstance } from 'services/http/httpClients';
import { accountRefreshToken } from 'utils/keycloak';
import currentClientStorage from 'storage/CurrentClientStorage';

export function fetchAccessibleClients(page, clientName, pageSize = 10) {
    return axiosInstance()
        .get(`/api/v1/user/my-accessible-clients?page=${page}&page_size=${pageSize}&client_name=${clientName}`)
        .then(response => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error('Something went wrong');
        });
}

export function getCurrentUserInfo() {
    return axiosInstance()
        .get('/api/v1/user/info')
        .then(response => {
            if (response.status === 200) {
                return response.data;
            }
            throw new Error('Something went wrong');
        });
}

export async function updateClientOnLocalStorage(client) {
    currentClientStorage.set(
        JSON.stringify({
            id: client.client_id,
            name: client.client_name,
            client_segment: client.client_segment || '',
        })
    );
    await accountRefreshToken();
}
